.mg-input-group span {
  position: absolute;
  font-size: 2.0rem;
  top: 1.0rem;
  color: #b1b3b6;
  width: 5.0rem;
  left: 0;
  display: flex;
  justify-content: center;
}

.mg-input-group input {
  width: calc(100% - 4.8rem);
  padding: 1.1rem 1.1rem 1.1rem 5.0rem;
  font-size: 1.6rem;
  border: none;
  color: #b1b3b6;
  background-color: #272d37;
}

.mg-input-group input:focus, .mg-input-group input:-webkit-autofill {
  background-color: #272d37;
  outline: none;
}

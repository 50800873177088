table.mg-table {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  margin-top: 1.0rem;
  padding: 0;
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
}

.mg-table tbody, .mg-table thead {
  display: block; /* ms ie & edge fix */
}

.mg-table tbody {
  max-height: calc(100vh - 30.2rem);
  overflow: auto;
}

.mg-table th, .mg-table td {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;

  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  min-width: 0;
  min-height: 2.0rem;
  line-height: 2.0rem;
  padding: 1.5rem 1.0rem;
}

.leaflet-map {
  height: 100%;
  background: grey;
  position: absolute;
  left: 67.8rem;
  width: calc(100% - 67.8rem);
  overflow: hidden;

  .leaflet-container {
    width: 100%;
    height: 100%;
  }
}

.rc-slider {
  height: 1.4rem;
  padding: 0.5rem 0;
}

.rc-slider-rail {
  height: 1.6rem;
}

.rc-slider-track {
  height: 1.6rem;
  background-color: #3fd5ca;
}

.rc-slider-handle {
  height: 2.6rem;
  width: 2.6rem;
  margin-left: -1.3rem;
  margin-top: -0.5rem;
  border-color: #3fd5ca;
}

.rc-slider-dot {
  width: 2.0rem;
  height: 2.0rem;
  border-color: #3fd5ca;
  border-width: 0.2rem;
  margin-left: -1.0rem;
  top: -0.2rem
}

.rc-slider-mark {
  top: 3.2rem;
}

.rc-slider-mark-text {
  color: white;
}

.rc-slider-mark-text-active {
  color: #999;
}

.score-distribution {
  font-size: 1.1rem;
  color: rgba(13, 13, 13, 0.51);
  width: 100%;
  border-collapse: collapse;
}

.score-distribution .h-axis {
  text-align: center;
}

.score-distribution .h-axis td:first-child {
  text-align: right;
}

.score-distribution tr:nth-child(2n) td {
  background-color: #fff;
}

.score-distribution tr:nth-child(2n) td:first-child {
  background-color: inherit;
}

.score-distribution td {
  border-right: solid .1rem rgba(151, 151, 151, 0.25);
  height: 1.9rem;
  width: 12.5%;
  text-align: center;
}

.score-distribution td:first-child {
  text-align: right;
}

.score-distribution td.v-axis {
  text-align: right;
}

.score-distribution td.v-axis span {
  position: relative;
  top: -1.0rem;
}

.score-distribution td i {
  background: #00afaa;
  padding: .2rem 0;
  border-radius: .7rem;
  display: inline-block;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-style: normal;
}

.score-distribution td i:hover {
  background-color: #383c45;
  cursor: pointer;
}

.score-distribution td i.point-size-XS {
  width: 3.5rem;
  background-color: #76e3dc;
}

.score-distribution td i.point-size-S {
  width: 4.5rem;
  background-color: #48dad0;
}

.score-distribution td i.point-size-M {
  width: 5.5rem;
  background-color: #00afaa;
}

.score-distribution td i.point-size-L {
  width: 6.5rem;
  background-color: #00908a;
}

.tooltip-on-hover {
  position: relative;
  display: inline-block;
}

.tooltip-on-hover .tooltip-text {
  visibility: hidden;
  background: #00050f;
  color: #fff;
  text-align: center;
  padding: .5rem .5rem;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  width: 10.0rem;
  margin-bottom: 1.0rem;
  margin-left: -5.5rem;
}

.tooltip-on-hover .tooltip-text:after {
  content: "";
  position: absolute;
  display: block;
  left: calc(50% - .5rem);
  bottom: -.5rem;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: .5rem .5rem 0 .5rem;
  border-color: #00050f transparent transparent transparent;
}

.tooltip-on-hover .tooltip-text p {
  padding: 0;
  margin: .2rem 0;
  white-space: nowrap;
  font-weight: normal;
}

.tooltip-on-hover .tooltip-text p.points-count {
  font-size: 1.3rem;
  color: #48dad0;
}

.tooltip-on-hover .tooltip-text p.point {
  font-size: 1.0rem;
  color: #fff;
}

.tooltip-on-hover:hover .tooltip-text {
  visibility: visible;
}

.mg-table-body-row {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.mg-table-body-row:nth-child(2n+1) {
   background-color: #e4e6e9;
 }

.mg-table-body-row:hover, .mg-table-body-row.hovered {
  background-color: rgba(72, 218, 208, 0.26);
}

.mg-table-body-cell {
  background: inherit;
  color: #333942;
  cursor: pointer;
  font-size: 1.6rem;
}

.mg-app-zoom {
  width: calc(100% - 90rem);
  min-width: 15rem;
  max-width: 35rem;
  z-index: 2;
  position: absolute;
  left: 75rem;
  top: 2rem;
  display: flex;
  background-color: inherit;
  padding: 0 1rem;
}

.mg-app-zoom .caption {
  display: inline-block;
  float: left;
  white-space: nowrap;
  color: #babcc4;
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-right: 2rem;
}

.mg-predictions-left {
  padding: 1rem;
  font-size: 1.65rem;
}

.mg-predictions-left input, .mg-predictions-left select {
  font-size: 1.7rem;
  padding: 0.3rem;
}

.mg-predictions-left .select-location-message {
  color: red;
  font-weight: bold;
}

.mg-predictions-left .form-row {
  margin: 2rem 0;
}

.mg-predictions-left .form-row > .form-row {
  margin: 0.8rem 0;
}

.mg-predictions-left .form-row + .form-row, .form-row > .form-row + .form-row {
  margin-top: 0;
}

.mg-predictions-left .coordinates > * {
  line-height: 3rem;
}

.mg-predictions-left .coordinates > span {
  width: 20rem;
}

.mg-predictions-left .mg-weekdays {
  margin-bottom: 1rem;
}

.mg-predictions-left .datetime {
  background: #333942;
  padding: 2rem;
  color: #999;
}

.mg-predictions-left .datetime .input {
  position: relative;
}

.mg-predictions-left .datetime .input input {
  padding-left: 2rem;
}

.mg-predictions-left .datetime .input svg {
  position: absolute;
  top: 0.5rem;
  color: #ffffff;
  cursor: pointer;
}

.mg-predictions-left .datetime .zone select {
  height: 3rem;
}

.mg-predictions-left .flex {
  display: flex;
  align-items: center;

  &.max-inputs {
    justify-content: space-between;

    .form-row {
      width: calc(50% - 1rem);
    }
  }

  .coords-input {
    flex-grow: 1;
  }
}

.mg-predictions-left .datetime .flex input, .mg-predictions-left .datetime .flex select {
  color: #ffffff;
  cursor: pointer;
}

.mg-predictions-left .flex select option {
  color: #000000;
}

.mg-predictions-left .datetime .flex > * {
  flex: 1 1 auto;
}

.mg-predictions-left .flex > .form-row {
  margin: 0 0 2rem 0;
}

.mg-predictions-left .rdtPicker {
  border: 1px solid #777;
}

.mg-predictions-left .mg-button {
  width: 50%;
  margin: 0 auto;
  margin-left: 25%;
  margin-top: 2rem;
  color: #444;
}

.mg-predictions-left .mg-button:disabled {
  opacity: 0.5;
}

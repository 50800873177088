.mg-log-out {
  display: inline-block;
  cursor: pointer;
  color: #48dad0;
  font-size: 1.8rem;
  font-weight: 300;
  background: transparent;
  border: none;
  float: right;
}

.mg-log-out:hover {
  color: white;
}

header.mg-header {
  height: 6.0rem;
  background-color: #383b45;
  position: relative;
  box-sizing: border-box;
  border-bottom: .1rem solid #0f1520;
}

header.mg-header .mg-logo {
  display: inline-block;
  width: 3.7rem;
  height: 3.3rem;
  left: 5.44rem;
  top: 1.7rem;
  position: absolute;
  background: url("./img/here_logo_white.svg") no-repeat;
  background-size: 3.7rem 3.3rem;
}

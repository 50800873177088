.mg-file-header {
  position: absolute;
  right: 4.3rem;
  top: 2.0rem;
}

.mg-file-header-info {
  color: white;
  font-size: 1.8rem;
}

.mg-file-header-size {
  font-size: 1.8rem;
  font-weight: 300;
  color: #babcc4;
}

.mg-file-header-info, .mg-file-header-size {
  margin-right: .5rem;
}

.mg-file-header-close {
  cursor: pointer;
  color: #48dad0;
  font-size: 1.8rem;
  font-weight: 100;
  background: transparent;
  border: none;
}

.mg-file-header-close:hover {
  color: white;
}

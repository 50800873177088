.mg-filter {
  padding: 2.4rem;
  background: #333942;
}

.mg-reset {
  display: inline-block;
  margin-left: 1.5rem;
  font-size: 1.6rem;
  text-align: center;
  color: #48dad0;
  cursor: pointer;
  background: transparent;
  border: none;
}

.mg-slider {
  display: flex;
  width: 100%;
  position: relative;
}

.mg-slider input {
  width: 6.5rem;
  height: 3.0rem;
  margin-top: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-align: center;
  color: #ffffff;
}

.mg-slider .rc-slider {
  margin: 2.0rem 1.5rem 1.5rem;
}

.mg-spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
}

.mg-spinner-container .mg-spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("./img/spinner.svg");
  background-size: 3.2rem 3.2rem;
  width: 3.2rem;
  height: 3.2rem;
  margin: auto;
}

.mg-table-header {
  display: flex;
}

.mg-table-header-item {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;

  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  min-width: 0;
  min-height: 2.0rem;
  line-height: 2.0rem;
  padding: 1.5rem 1.0rem;
  color: #6a6d74;
  background: #babcc4;
  font-size: 1.3rem;
  text-transform: uppercase;
  cursor: pointer;
}

.mg-table-header-item svg {
  color: rgb(162, 164, 172);
  padding-left: .5rem;
  font-size: 1.6rem;
  position: relative;
  top: -.1rem;
}

.mg-table-header-item-sorting svg {
  color: rgb(108, 111, 118);
}

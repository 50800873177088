.mg-weekdays {
  display: inline-block;
  padding: 0;
  font-size: 0;
  border-radius: .1rem;
}

.mg-weekdays li {
  display: inline-block;
  width: 4.5rem;
  height: 4.0rem;
  line-height: 4.0rem;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  font-size: 1.6rem;
  color: #48dad0;
  border: solid .1rem #48dad0;
  margin: 0;
}

.mg-weekdays li.selected {
  background-color: #48dad0;
  color: #333942;
}

.mg-debug-subscriptions {
  h3 {
    margin-bottom: 0;
  }

  lui-textfield {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    input, textarea {
      color: black!important;
    }
  }

  textarea {
    height: 10rem;
  }

  .mg-debug-subscriptions-actions {
    float: right;

    lui-button {
      margin-top: 1rem;
      margin-left: 1rem;
    }
  }

  .mg-debug-subscriptions-res {
    margin-top: 9rem;
  }
}

.mg-button {
  width: 100%;
  border: none;
  border-radius: 0;
  background: #3fd5ca;
  color: white;
  font-size: 1.65rem;
  padding: .8rem 2.0rem;
  cursor: pointer;
}

.mg-button:focus {
  outline: none;
}

.mg-button.disabled {
  cursor: not-allowed;
}

.mg-button.delete {
  background: #ec5e5e;
}

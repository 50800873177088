.mg-predictions-list {
  list-style-type: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  margin-top: 1rem;
}

.mg-predictions-list li {
  margin-bottom: 0.5rem;
  border: 0.1rem solid #777;
  border-left: 0;
  border-radius: 5rem;
  background: rgba(63, 213, 202, 0.2);
  padding: 1rem;
}

.mg-predictions-list li.hovered {
  background: rgba(63, 213, 202, 0.4);
}

.mg-predictions-list.selected li {
  background: rgba(63, 213, 202, 0.4);
}

.mg-predictions-list li.route {
  margin-left: 2rem;
  display: block;
}

.mg-predictions-list.selected li.route.hovered {
  background: rgba(63, 213, 202, 0.7);
}

.mg-predictions-list .prediction-num {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  display: inline-block;
  margin-right: 1rem;
}

.mg-predictions-list .prediction-score {
  margin-left: 1rem;
  font-weight: bold;
}

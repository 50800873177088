.mg-file-loader {
  border-radius: 1.0rem;
  border: dashed .2rem #babcc4;
  font-size: 1.6rem;
  padding: 1.8rem 1.9rem 2.5rem 1.9rem;
  color: #babcc4;
  background-color: #333942;
}
.mg-file-loader.dragover {
  opacity: .5;
}
.mg-file-loader.dragover > * {
  pointer-events: none;
}
.mg-file-loader h5 {
  font-size: 2.2rem;
  font-weight: 500;
  margin: .5rem 0;
}
.mg-file-loader p {
  margin: .5rem 0;
}
.mg-file-loader label {
  cursor: pointer;
  color: #48dad0;
}
.mg-file-loader label input {
  display: none;
}

.mg-map {
  height: 100%;
  background: grey;
  position: absolute;
  left: 67.8rem;
  width: calc(100% - 67.8rem);
  overflow: hidden;
}

.mg-map.reduced {
  width: calc(100% - 79.8rem);
  left: 79.8rem;
}

.mg-collapse {
  border-bottom: .1rem solid silver;
  border-top: .1rem solid silver;
  display: block;
  padding: .5rem 0;
  margin-bottom: 2.0rem;
}

.mg-collapse .label {
  font-size: 1.3rem;
  text-align: left;
  color: #333942;
  text-transform: uppercase;
  cursor: pointer;
  line-height: 3.0rem;
}

.mg-collapse svg {
  float: right;
  margin-top: .8rem;
}

.mg-collapse .container {
  max-height: 0;
  transition: max-height .5s;
  overflow: hidden;
}

.mg-collapse .container.open {
  max-height: 200.0rem;
  overflow: hidden;
}

.mg-floating-selected-commute h2 {
  font-size: 1.3rem;
  text-align: left;
  color: #333942;
  text-transform: uppercase;
  font-weight: 400;
}

.mg-floating-selected-commute .routes {
  font-size: 1.6rem;
  text-align: left;
  color: #333942;
  width: 100%;
  border-collapse: collapse;
}

.mg-floating-selected-commute .routes td {
  border-bottom: solid .2rem rgba(51, 57, 66, 0.25);
  padding: 1.0rem .5rem;
}

.mg-floating-selected-commute .routes td:last-child {
  text-align: right;
}

.mg-floating-selected-commute .routes tr:last-child td {
  border-bottom-width: 0;
}

.mg-floating-selected-commute .routes i {
  text-align: inherit;
  display: block;
  opacity: 0.5;
  font-size: 1.3rem;
  font-weight: 300;
  font-style: normal;
  color: #333942;
  margin: .3rem .5rem 0 0;
}

.mg-floating-selected-commute .routes .icon {
  background: #1188dd;
  display: inline-block;
  width: .4rem;
  height: 3.0rem;
  position: relative;
}

.mg-floating-selected-commute .routes .icon:before, .mg-floating-selected-commute .routes .icon:after {
  content: "";
  display: inline-block;
  width: .4rem;
  height: .4rem;
  border: solid .4rem #1188dd;
  position: absolute;
  border-radius: 50%;
  background: #f2f2f2;
  left: -.4rem;
}

.mg-floating-selected-commute .routes .icon:before {
  top: 0;
}

.mg-floating-selected-commute .routes .icon:after {
  bottom: 0;
}

nav.mg-nav {
  position: absolute;
  left: 12.0rem;
}

nav.mg-nav a {
  color: #babcc4;
  font-size: 1.8rem;
  text-decoration: none;
  letter-spacing: -.02rem;
  display: inline-block;
  height: 4.8rem;
  line-height: 4.8rem;
  margin-left: 1.5rem;
  padding: .7rem .5rem 0 .5rem;
}

nav.mg-nav a:hover {
  color: #fff;
}

nav.mg-nav a.active {
  color: #fff;
  border-bottom: .4rem #48dad0 solid;
}

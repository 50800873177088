.mg-radio-group .mg-radio-option {
  color: #babcc4;
  cursor: pointer;
  position: relative;
  margin-right: 1.0rem;
  font-size: 1.65rem;
}

.mg-radio-group .mg-radio-option:last-child {
  margin-right: 0;
}

.mg-radio-group .mg-radio-option input {
  visibility: hidden;
  height: 2.0rem;
  width: 2.0rem;
  margin: 0;
}

.mg-radio-group .mg-radio-option i {
  background-color: #babcc4;
  border: .5rem solid #babcc4;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: calc(50% - 1.0rem);
  height: 1.0rem;
  width: 1.0rem;
  cursor: pointer;
}

.mg-radio-group .mg-radio-option input:checked + i {
  background-color: #333942;
  border-color: #48dad0;
}

.mg-tabs {
  list-style-type: none;
  margin: 1.0rem 0;
  padding: 0;
  overflow: hidden;
}

.mg-tabs-item {
  float: left;
  position: relative;
  box-sizing: border-box;

  line-height: 3.0rem;
  flex: 1;
  border-bottom: .3rem solid transparent;
  cursor: pointer;
  text-align: center;
  z-index: 2;
  font-weight: 700;
  color: rgba(51, 57, 66, 0.51);
  text-transform: uppercase;
  font-size: 1.3rem;
  padding: 0 1.5rem;
}

.mg-tabs-item-selected {
   border-bottom: .4rem solid #00afaa;
   color: #333942;
   cursor: default;
 }

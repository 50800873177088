.mg-login {
  height: calc(100vh - 6.0rem);
  background-color: #333942;
  display: flex;
  flex-flow: column;
  position: relative;
}

.mg-login h3 {
  padding: 0 0 1.5rem 0;
  margin: 0;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: left;
  color: #babcc4;
}

.mg-login h4 {
  padding: 0 0 1.5rem 0;
  margin: 0;
  font-size: 1.8rem;
  font-weight: 300;
  text-align: left;
  color: #babcc4;
}

.mg-login .inner {
  width: 36.0rem;
  margin: auto;
}

.mg-login .inner .block {
  position: relative;
  padding-bottom: 7.0rem;
}

.mg-login .mg-button {
  width: 50%;
}

.mg-login .server-error {
    margin: .8rem;
    color: red;
    text-align: center;
}

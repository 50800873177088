.mg-floating-panel {
  position: fixed;
  top: 6.0rem;
  height: calc(100% - 6.0rem);
  width: 0;
  z-index: 2;
  transition: left .5s;
}

.mg-floating-panel .left-transparent {
  position: absolute;
  height: 100%;
  background-color: #434750;
  opacity: .7;
}

.mg-floating-panel .panel {
  position: absolute;
  box-sizing: border-box;
  padding: 2.0rem;
  height: 100%;
  background-color: #f2f2f2;
  box-shadow: -.5rem .0rem .5rem -.3rem black;
  overflow-y: auto;
}

.mg-collapse .container>ul {
  background: transparent !important;
  font-size: 1.3rem;
}

.mg-debug {
  padding: 1.5rem;
}

.mg-debug .left, .mg-debug .right {
  width: calc(50% - 1.5rem);
}

.mg-debug .left {
  float: left;
}

.mg-debug .left form {
  margin-bottom: 1.5rem;
}

.mg-debug .left form .form-group {
  width: 100%;
  position: relative;
  margin: 1.0rem 0;
  display: inline-block;
}

.mg-debug .left form .form-group label {
  width: 30%;
  float: left;
}

.mg-debug .left form .form-group input, .mg-debug .left form .form-group textarea {
  width: calc(70% - .8rem);
  float: right;
  display: inline-block;
  border: #000000 solid 1px;
}

.mg-debug .left form .form-group input.ng-invalid, .mg-debug .left form .form-group textarea.ng-invalid {
  border: .2rem solid red;
}

.mg-debug .left form .form-group input:focus, .mg-debug .left form .form-group textarea:focus {
  outline: none;
}

.mg-debug .left form .form-group textarea {
  height: 10.0rem;
}

.mg-debug .right {
  float: right;
  font-size: 1.3rem;
}

.mg-subscriptions-left {
  padding: 10px;
  color: black;

  label {
    lui-textfield {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;

      input {
        color: black!important;
      }
    }
  }

  & > div {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  lui-spinner {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

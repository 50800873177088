.mg-start-end .from {
  position: relative;
}

.mg-start-end .from .double-arrows-icon {
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  left: .5rem;
  top: calc(45% + .9rem);
  font-family: FontAwesome;
  font-style: normal;
  color: #6a6d74;
}

.mg-start-end .from .double-arrows-icon:before {
  content: "\f176";
  display: inline-block;
  position: absolute;
  left: .6rem;
  top: 0;
}

.mg-start-end .from .double-arrows-icon:after {
  content: "\f175";
  display: inline-block;
  position: absolute;
  right: .6rem;
  bottom: 0;
}

.mg-start-end .from, .mg-start-end .to {
  display: flex;
  padding-bottom: 2.0rem;
}

.mg-start-end .icon {
  display: inline-block;
  background: #48dad0;
  height: 3.3rem;
  width: 3.3rem;
  min-width: 3.3rem;
  text-align: center;
  border-radius: 50%;
  line-height: 3.3rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: #333942;
}

.mg-start-end .content {
  padding-left: 1.0rem;
}

.mg-start-end .content .address {
  font-size: 2.2rem;
  text-align: left;
  color: #333942;
}

.mg-start-end .content .coords {
  opacity: 0.5;
  font-size: 1.3rem;
  text-align: left;
  color: #333942;
}

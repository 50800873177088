.mg-timetable {
  background: #333942;
  padding: 1rem 1rem 0 1rem;
}

.mg-timetable .visualization-svg {
  width: 100%;
}

.mg-timetable .visualization-svg .day-label text {
  text-anchor: middle;
  fill: #999;
}

.mg-timetable .visualization-svg .day-label circle {
  stroke: #aaa;
  stroke-width: 2;
  fill: none;
}

.mg-timetable .visualization-svg .lines-wrapper {
  stroke: #999;
  stroke-dasharray: 2, 2;
  stroke-width: 1;
}

.mg-timetable .visualization-svg .hour {
  font-size: 1rem;
  fill: #aaa;
  text-anchor: middle;
}

.mg-timetable .visualization-svg .time-marker {
  fill: yellow;
}

.mg-timetable .visualization-svg .time-marker.hovered {
  fill: orange;
  r: 1rem;
  z-index: 10;
}

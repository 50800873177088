.mg-user-menu-toggler {
  position: absolute;
  right: 0;
  top: 1.5rem;
  color: #babcc4;
  font-size: 1.6rem;
  font-style: normal;
  margin-right: 1.5rem;
}

.mg-user-menu-toggler i {
  font-style: normal;
  color: #fff;
}

.mg-user-menu-toggler .toggle-icon {
  font-size: 2.6rem;
  position: absolute;
  top: .1rem;
  right: .8rem;
}

.mg-user-menu-toggler .wrapper:hover svg {
  color: #fff !important;
}

.mg-user-menu-toggler .wrapper {
  padding: 0 4.0rem 0 1.0rem;
  line-height: 3.5rem;
  border-radius: 1.5rem;
  cursor: pointer;
}

.mg-user-menu-toggler .wrapper:hover {
  background-color: #555;
}

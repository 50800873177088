.mg-close-floating-panel {
  font-size: 4.2rem;
  color: #6a6d74;
  display: block;
  position: absolute;
  top: 2.5rem;
  left: 51.0rem;
  line-height: 2.5rem;
  cursor: pointer;
}
